<template>
  <div>
    <el-upload
        class="upload-demo"
        :action="uploadURL"
        :headers="headerObj"
        :on-preview="handlePreview"
        :on-success="handleSuccess"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :multiple="false"
        :limit="maxUpload"
        :on-exceed="handleExceed"
        :file-list="fileList"
        accept=".pdf,.PDF">
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
import config from '@/config';
import { getToken } from '@/utils/auth';

export default {
  name: 'update-pdf',
  data() {
    return {
      uploadURL: config.baseUrl.dev + '/api/Pdf/upload',
      // 限制单次上传数
      maxUpload: 1,
      headerObj: {
        'api-auth': getToken(),
        'access-token': config.AccessToken
      },
      fileList: []
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
      // return this.$emit('selectPdf', file);
      if(file.response.data.fileUrl) {
        // window.location = url
        window.open(file.response.data.fileUrl, '_blank');
      }
    },
    // 监听图片上传成功的事件
    handleSuccess(response, file, fileList) {
      if(response.code !== 1) {
        return this.$message.error(response.msg || '上传失败')
      }
      this.$message.success('上传成功')

      return this.$emit('select', response.data.url);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.maxUpload} 个文件`)
      // this.$message.warning(`当前限制选择 ${this.maxUpload} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    }
  }
}
</script>